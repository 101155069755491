import React, { useEffect, useState } from "react";
import colors from "../colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faSpotify,
  faSoundcloud,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { useMobile } from "../App";

import { animated, useSpring, useTrail } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import translations from "../translations";
import axios from "axios";

export default function Contact() {
  const [formRef, inView] = useInView({
    triggerOnce: false,
    threshold: 0.05,
  });
  const isMobile = useMobile();

  const [language, setLanguage] = useState("pt");

  useEffect(() => {
    let userLanguage = navigator.language || navigator.userLanguage || "en-US";

    userLanguage = userLanguage.split("-")[0];

    // Match exact keys in translations or fallback to a default
    setLanguage(translations[userLanguage] ? userLanguage : "en");
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        padding: isMobile ? "0px 24px" : "48px 10%",
        overflowX: "hidden",
      }}
    >
      <TextContainer lang={language} />
      <div ref={formRef}>
        <FormContainer inView={inView} lang={language} />
      </div>
      {isMobile && <SocialMedias lang={language} />}
    </div>
  );
}

const TextContainer = ({ lang }) => {
  const [hasFinishedTitle, setHasFinishedTitle] = useState(false);
  const isMobile = useMobile();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const textsSpring = useSpring({
    from: { opacity: 0, transform: "translate3d(-100%, 0, 0)" },
    to: {
      opacity: hasFinishedTitle ? 1 : 0,
      transform: hasFinishedTitle
        ? "translate3d(0, 0, 0)"
        : "translate3d(-100%, 0, 0)",
    },
    config: { duration: 800 },
  });

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        paddingTop: 24,
        color: colors.white,
      }}
    >
      {inView && (
        <AnimatedWritingText
          Component="h1"
          style={{
            fontSize: isMobile ? 32 : 40,
            marginTop: isMobile ? 12 : 24,
            color: colors.pink,
          }}
          text="Norah Valente"
          inView={inView}
          setHasFinishedText={setHasFinishedTitle}
        />
      )}

      <animated.div
        style={{
          fontSize: isMobile ? 18 : 20,
          ...textsSpring,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
          // marginBottom: 24,
        }}
      >
        <p style={{ marginTop: 12, lineHeight: 1.5 }}>
          {translations[lang].description1}
          <br />
          <br />
          {translations[lang].description2}
        </p>

        {!isMobile && <SocialMedias lang={lang} />}
      </animated.div>
    </div>
  );
};

const SocialMedias = ({ lang }) => {
  const isMobile = useMobile();

  return (
    <div style={{ color: colors.white }}>
      <h3 style={{ marginTop: 24, textAlign: isMobile ? "center" : "start" }}>
        {translations[lang].mySocialMedias}
      </h3>
      <div
        style={{
          marginTop: 12,
          display: "flex",
          justifyContent: isMobile ? "center" : "flex-start",
        }}
      >
        <SocialMediaIcon
          icon={faInstagram}
          link="https://www.instagram.com/norahvalentemusic/"
          isFirst
        />
        <SocialMediaIcon
          icon={faSpotify}
          link="https://open.spotify.com/intl-pt/artist/6DwzQnO3R2E99LiC573wJj?si=CO4pWEUvSuWruQZbZjIIGQ"
        />
        <SocialMediaIcon
          icon={faSoundcloud}
          link="https://soundcloud.com/norahvalentemusic"
        />
        <SocialMediaIcon
          icon={faYoutube}
          link="https://www.youtube.com/@norahmusicdj"
        />
      </div>
    </div>
  );
};

const AnimatedWritingText = ({
  Component = "span",
  text,
  style,
  inView,
  setHasFinishedText,
}) => {
  const textChars = text.split("");

  const trailText = useTrail(textChars.length, {
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 50,
    onRest: () => {
      setHasFinishedText(true);
    },
  });

  return (
    <Component style={style}>
      <animated.span>
        {trailText.map((props, index) => {
          return (
            <animated.span key={index} style={{ ...props }}>
              {inView && textChars[index] === " " ? "\u00A0" : textChars[index]}
            </animated.span>
          );
        })}
      </animated.span>
    </Component>
  );
};
const SocialMediaIcon = ({ icon, link, isFirst = false }) => {
  const [isHoving, setIsHoving] = useState(false);
  const isMobile = useMobile();

  return (
    <FontAwesomeIcon
      onMouseEnter={() => setIsHoving(true)}
      onMouseLeave={() => setIsHoving(false)}
      color={isHoving && !isMobile ? colors.pink : colors.white}
      style={{ marginLeft: isFirst ? 0 : 16, cursor: "pointer" }}
      icon={icon}
      size={"3x"}
      onClick={() => window.open(link, "_blank")}
    />
  );
};

const FormContainer = ({ inView, lang }) => {
  const [isHovingButton, setIsHovingButton] = useState(false);

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const isMobile = useMobile();

  const containerSpring = useSpring({
    from: {
      opacity: 0,
      transform: `translate3d(${isMobile ? "-100%" : "100%"}, 0, 0)`,
    },
    to: {
      opacity: inView ? 1 : 0,
      transform: inView
        ? "translate3d(0, 0, 0)"
        : `translate3d(${isMobile ? "-100%" : "100%"}, 0, 0)`,
    },
    config: { duration: 800 },
  });
  return (
    <animated.div
      style={{
        width: isMobile ? "100%" : 550,
        marginLeft: isMobile ? 0 : 48,
        marginTop: isMobile ? 24 : 0,
        background: `linear-gradient(to bottom, ${colors.pink}, ${colors.darkPink})`,
        color: colors.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        ...containerSpring,
      }}
    >
      <div style={{ margin: 24 }}>
        <div style={{ textAlign: "center" }}>
          <h2 style={{ fontSize: isMobile ? 24 : 32 }}>
            {translations[lang].contactForm.title}
          </h2>
          <h4
            style={{
              marginTop: isMobile ? 4 : 8,
              fontSize: isMobile ? 14 : 16,
            }}
          >
            {translations[lang].contactForm.description}{" "}
          </h4>

          <InputText
            label={translations[lang].contactForm.name}
            text={name}
            setText={setName}
          />
          <InputText
            label={translations[lang].contactForm.email}
            text={mail}
            setText={setMail}
          />
          <TextArea
            label={translations[lang].contactForm.message}
            text={message}
            setText={setMessage}
          />
          <button
            onMouseEnter={() => setIsHovingButton(true)}
            onMouseLeave={() => setIsHovingButton(false)}
            style={{
              backgroundColor: isHovingButton ? colors.white : colors.black,
              color: colors.pink,
              transition: "color 300ms, background-color 300ms",
              width: "100%",
              fontSize: 24,
              cursor: "pointer",
              padding: 10,
              fontWeight: "bold",
              marginTop: 16,
            }}
            onClick={async () => {
              try {
                await axios.post("https://norah-server.vercel.app/send-email", {
                  mail,
                  name,
                  text: message,
                });
              } catch (err) {
                console.log(err);
              }

              setMail("");
              setName("");
              setMessage("");
            }}
          >
            enviar
          </button>
        </div>
      </div>
    </animated.div>
  );
};

const InputText = ({ label, text, setText }) => {
  const isMobile = useMobile();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: 16,
      }}
    >
      <b style={{ fontSize: isMobile ? 14 : 16 }}>{label}</b>
      <input
        value={text}
        onChange={(e) => setText(e.target.value)}
        set
        style={{
          borderBottom: `1px solid ${colors.white}`,
          padding: 8,
          color: "white",
          fontSize: isMobile ? 20 : 24,
          width: "calc(100% - 24px)",
        }}
      />
    </div>
  );
};

const TextArea = ({ label, text, setText }) => {
  const isMobile = useMobile();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "calc(100% - 24px)",

        marginTop: 16,
      }}
    >
      <b style={{ fontSize: isMobile ? 14 : 16 }}>{label}</b>
      <textarea
        type="textarea"
        rows={4}
        style={{
          border: `1px solid ${colors.white}`,
          padding: 8,
          background: "none",
          color: "white",
          fontSize: isMobile ? 20 : 24,
          width: "100%",
          marginTop: 8,
        }}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
    </div>
  );
};
