import { useState, useEffect, createContext, useContext } from "react";

import Hero from "./components/Hero";
import Tracks from "./components/Tracks";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ReactGA from "react-ga4";

const MobileContext = createContext();

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [windowWidthDiff, setWindowWidthDiff] = useState(
    window.innerWidth <= 768
      ? window.screen.width / 300
      : window.innerWidth / 1440
  );

  useEffect(() => {
    ReactGA.initialize("G-61QKQV740N");

    ReactGA.send({ hitType: "pageview", page: "/" });

    const handleResize = () => {
      const isCurrentlyMobile = window.innerWidth <= 768;
      setIsMobile(isCurrentlyMobile);
      setWindowWidthDiff(
        isCurrentlyMobile ? window.screen.width / 300 : window.innerWidth / 1440
      );
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <MobileContext.Provider value={isMobile}>
      {/* Pass down windowWidthDiff and isMobile as props if needed */}
      <Hero windowWidthDiff={windowWidthDiff} isMobile={isMobile} />
      {/* Uncomment to include Tracks component */}
      <Tracks windowWidthDiff={windowWidthDiff} isMobile={isMobile} />
      <Contact />
      <Footer />
    </MobileContext.Provider>
  );
}

export const useMobile = () => {
  return useContext(MobileContext);
};

export default App;
